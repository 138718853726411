.box_input {
    display:flex;
    width:522px;
    gap:12px;
}
.box_main {
    display:flex;
    flex-direction: column;
    align-items: center;
}
.lists_circle {
    list-style-type: none;
    display: flex;
    gap: 16px;
}