.box_menu {
max-width:924px;
display: flex;
justify-content:center;
align-items:center;
margin-bottom: 48px;
}
.main {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.box_diogram {
    height:340px;
    max-width: 1000px;
    list-style-type: none;
    display: flex;
    flex-direction:row;
    gap: 10px;
    align-items:end;
}
.list_diogram {
width:50px;

}
